import { useState, useEffect } from "react";
import { navigateToUrl } from "single-spa";
import {
  HeadTitle,
  Container,
  SelectAsulado,
  InputAsulado,
  DatePickerAsulado,
  ReusableButton,
  LoaderAsulado,
} from "@mfe/js-asulado-uiutils";

import { MsalProvider, msalInstance, useMsal } from "@mfe/react-asulado-msal";
import { fetchTokenApi, getService, API } from "@mfe/ts-asulado-utilitaries";

export const Busqueda = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <BusquedaComponent />
    </MsalProvider>
  );
};

const BusquedaComponent: React.FC = () => {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);

  const [opcionesTipoDocumento, setOpcionesTipoDocumento] = useState([]);
  const [opcionesCiudades, setOpcionesCiudades] = useState([]);

  const [selectedCiudad, setSelectedCiudad] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeCiudad = (event) => {
    setSelectedCiudad(event.target.value);
  };

  const handleChangeTypeId = (event) => {
    setSelectedTypeId(event.target.value);
  };

  useEffect(() => {
    const fetchQueryToken = async () => {
      try {
        const token = await fetchTokenApi(instance, accounts);
        localStorage.setItem("access_token", token);

        await fetchQueryResults();
      } catch (error) {
        console.error(error);
      }
    };

    const fetchQueryResults = async () => {
      const tokenAuthorization = localStorage.getItem("access_token");

      /*if (!tokenAuthorization) {
        console.error("No se encontró el token de autorización");
        return;
      }*/

      const tokenApi = {
        Authorization: `Bearer ${tokenAuthorization}`,
      };

      try {
        const respuestaCiudades = await getService(
          `${API.catalogo}ciudades`,
          tokenApi
        );
        if (typeof respuestaCiudades === "object") {
          localStorage.setItem("ciudades", JSON.stringify(respuestaCiudades));
          setOpcionesCiudades(respuestaCiudades);
        } else {
          console.error(`Error al obtener ciudades: ${respuestaCiudades}`);
        }

        const respuestaTipoDocumentos = await getService(
          `${API.catalogo}tipodocumento`,
          tokenApi
        );
        if (typeof respuestaTipoDocumentos === "object") {
          setOpcionesTipoDocumento(respuestaTipoDocumentos);
        } else {
          console.error(
            `Error al obtener tipo de documentos: ${respuestaTipoDocumentos}`
          );
        }
      } catch (error) {
        console.error("La consulta falló:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQueryToken();
  }, [instance, accounts]);

  const handleClickNext = () => {
    const data = {
      periodo: selectedDate || null,
      codigoCiudad: selectedCiudad || null,
      codigoTipoDocumento: selectedTypeId || null,
      numeroIdentificacion: inputValue || null,
    };

    localStorage.setItem("filtroSeleccionado", JSON.stringify(data));
    navigateToUrl("/app#aprobacion");
  };

  return (
    <>
      <Container sx={{ maxWidth: "80%" }} size="xl">
        <HeadTitle title="Búsqueda pensionados" />
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <LoaderAsulado></LoaderAsulado>
          </div>
        ) : (
          <>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "0 0 10px 10px",
                margin: "10px 0",
                padding: "20px 40px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 30,
                }}
              >
                <SelectAsulado
                  className="custom-select"
                  label="Ciudad"
                  value={selectedCiudad}
                  options={opcionesCiudades}
                  optionCode="codigoMunicipio"
                  optionValue="municipio"
                  onChange={handleChangeCiudad}
                />

                <DatePickerAsulado
                  value={selectedDate}
                  onChange={handleDateChange}
                  label="Periodo"
                  className="custom-datepicker"
                />

                <SelectAsulado
                  className="custom-select"
                  label="Tipo identificación"
                  value={selectedTypeId}
                  options={opcionesTipoDocumento}
                  optionCode="idDocumento"
                  optionValue="descripcion"
                  onChange={handleChangeTypeId}
                />

                <InputAsulado
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Ingresar numero identificación"
                  label="N° identificación"
                  className="custom-input"
                />
              </div>

              <div style={{ textAlign: "end", marginTop: 15 }}>
                <ReusableButton
                  text="Buscar"
                  onClick={handleClickNext}
                  className="submit-btn"
                />
              </div>
            </div>

            {/* <div
              style={{
                backgroundColor: "white",
                borderRadius: 10,
                margin: "10px 0",
                padding: "1px 40px 20px",
              }}
            >
              <h3>Ejecutar python</h3>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 30,
                  alignItems: "end",
                }}
              >
                <DatePickerAsulado
                  value={selectedDate}
                  onChange={handleDateChangePython}
                  label="Periodo"
                  className="custom-datepicker"
                />
                <div>
                  <ReusableButton
                    text="Ejecutar"
                    onClick={handleClickPython}
                    className="submit-btn"
                  />
                </div>
              </div>
            </div> */}
          </>
        )}
      </Container>
    </>
  );
};
